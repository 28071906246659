<template lang='pug'>
  .awesome-profile-security__content.awesome-profile-security__content-two-factor(v-loading='isLoading')
    .awesome-profile-security__content-title.awesome-profile-security__content-two-factor__title
      span Двухфакторная аутентификация (2FA)
      span.awesome-profile-security__content-two-factor__status(
        :class='{ active: isGoggle2faActivated }'
      ) {{ isTwoFactorStatusText }}
    .awesome-profile-security__content-desc(v-if='isGoggle2faDeactivated')
      span Для усиления защиты рекомендуем активировать двухфакторную аутентификацию
    .awesome-profile-security__content-two-factor__enable(v-if='isGoggle2faDeactivated')
      ProfileSecurityTwoFactorEnable(@update='handleUpdateStatus')
    .awesome-profile-security__content-two-factor__stop(v-else)
      ProfileSecurityTwoFactorPauseOrStop(@update='handleUpdateStatus')
</template>

<script>
import { mapGetters } from 'vuex';
import ProfileSecurityTwoFactorEnable
  from '@/components/AwesomeComponents/Account/Profile/Security/components/ProfileSecurityTwoFactorEnable.vue';
import ProfileSecurityTwoFactorPauseOrStop
  from '@/components/AwesomeComponents/Account/Profile/Security/components/ProfileSecurityTwoFactorPauseOrStop.vue';
import { FACTOR_CONNECT, FACTOR_ENABLE } from '@/store/actions/twoFactor';
import { SECURITY_REQUEST } from '@/store/actions/security';

export default {
  name: 'ProfileSecurityTwoFactor',
  components: { ProfileSecurityTwoFactorPauseOrStop, ProfileSecurityTwoFactorEnable },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      'security': 'getSecurity',
      'loading': 'loading',
    }),
    isTwoFactorStatusText() {
      switch (this.security.google2fa_status) {
        case 'deactivated':
          return 'не активна';
        case 'paused':
          return 'приостановлена';
        case 'activated':
          return 'активна';
        default:
          return '';
      }
    },
    isGoggle2faActivated() {
      return this.security.google2fa_status === 'activated';
    },
    isGoggle2faDeactivated() {
      return this.security.google2fa_status === 'deactivated';
    },
    isLoading() {
      return this.loading(FACTOR_CONNECT) === 'loading' || this.loading(FACTOR_ENABLE) === 'loading' || this.loading(SECURITY_REQUEST) === 'loading';
    },
  },
  methods: {
    handleUpdateStatus() {
      this.$store.dispatch(SECURITY_REQUEST);
    },
  },
};
</script>

<style scoped>

</style>
