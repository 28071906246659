<template lang="pug">
  .awesome-profile-security__content-two-factor__enable-container
    .awesome-profile-security__content-two-factor__enable-subheader
      span Чтобы активировать 2FA вам необходимо установить
      br
      a.factor__link(href="https://support.google.com/accounts/answer/1066447?co=GENIE.Platform%3DAndroid&hl=ru" target="_blank")
        | приложение Google Authenticator

    .awesome-profile-security__content-two-factor__enable-stepper-container
      .awesome-profile-security__content-two-factor__enable-stepper-wrapper
        .awesome-profile-security__content-two-factor__enable-stepper__number
          span 1
      .awesome-profile-security__content-two-factor__enable-stepper-content
        .awesome-profile-security__content-two-factor__enable-stepper__subheader
          span Отсканируйте QR код с помощью приложения
        img(:src="factor.QRCodeImage" alt="QR" width="90" height='90')
        .awesome-profile-security__content-two-factor__enable-stepper__subheader
          span или введите ключ установки вручную
        .awesome-profile-security__input-two-factor-key-container
          input.awesome-profile-security__input-two-factor-key(
            v-model="factor.google2fa_secret"
            ref="inputCodeKey"
            readonly
          )
          .awesome-profile-security__input-two-factor-key__icon(@click="handleCopyKey")
            img(src='/awesome-icons/copy.svg')
        .awesome-profile-security__content-two-factor__enable-desc
          span Необходимо сохранить ключ установки, который
          br
          | понадобится вам в случае утери телефона

    .awesome-profile-security__content-two-factor__enable-stepper-container
      .awesome-profile-security__content-two-factor__enable-stepper-wrapper
        .awesome-profile-security__content-two-factor__enable-stepper__number
          span 2
      .awesome-profile-security__content-two-factor__enable-stepper-content
        .awesome-profile-security__content-two-factor__enable-stepper__subheader
          span Введите 6-ти значный сгенерированный код
        .awesome-profile-security__input-two-factor-code-container
          .awesome-profile-security__input-two-factor-code
            input(
              placeholder='Код Google Authenticator',
              v-model.trim='google2fa_secret'
              maxlength='6'
            )
            .awesome-profile-security__input-two-factor-code__error-text(v-if='(getErrors && getErrors.message) && getErrors.errors.google2fa_secret')
              template(v-for='error in getErrors.errors.google2fa_secret')
                span {{ error }}
    .awesome-profile-security__content-two-factor__footer
      button.awesome-btn(@click="handleEnable" :disabled="isDisabledBtn")
        span Активировать
</template>

<script>
import { FACTOR_CONNECT, FACTOR_ENABLE } from "@/store/actions/twoFactor";
import { mapGetters } from "vuex";
import { SECURITY_REQUEST } from "@/store/actions/security";

export default {
  name: "ProfileSecurityTwoFactorEnable",
  data() {
    return {
      google2fa_secret: '',
    };
  },
  computed: {
    ...mapGetters({
      'factor': 'getFactor',
      'getErrors': 'getFactorErrors',
    }),
    isDisabledBtn() {
      return this.google2fa_secret.length !== 6;
    },
  },
  mounted() {
    this.$store.dispatch(FACTOR_CONNECT);
  },
  methods: {
    handleCopyKey() {
      const google2fa_secret_key = this.$refs.inputCodeKey.value;
      navigator.clipboard.writeText(google2fa_secret_key).then(() => {
        this.$message({
          message: 'Ключ установки успешно скопированы',
          type: 'success',
        });
      }).catch(err => {
        console.log('Ошибка при копировании ключа установки', err);
      });
    },
    handleEnable() {
      this.$store.dispatch(FACTOR_ENABLE, {
        google2fa_secret: this.google2fa_secret,
      }).then(() => {
        this.google2fa_secret = "";
        this.$emit('update');
      });
    },
  },
};
</script>

<style scoped>

</style>
