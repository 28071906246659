<template lang='pug'>
  el-dialog.dialog-add-ip-address(
    title='Добавление IP-адреса'
    width='370px'
    class='awesome-el-dialog'
    :closeOnClickModal="false"
    :visible.sync='dialogVisible'
    :show-close='false'
    @close='handleCloseDialog'
  )
    .dialog-add-ip-address__content
      .dialog-add-ip-address__input-container
        .dialog-add-ip-address__input
          input(
            placeholder='IP-адрес',
            v-model.trim='ip'
            @input='validateIPAddress($event.target.value)'
          )
          .dialog-add-ip-address__input-error__text(v-if='localErrors.ip')
            span {{ localErrors.ip }}
        .dialog-add-ip-address__input(v-if="is2FaActive")
          input(
            placeholder="Код Google Authenticator",
            v-model.trim="google2fa_secret"
            maxlength="6"
            @input='localErrors.google2fa = ""'
          )
          .dialog-add-ip-address__input-error__text(v-if="localErrors.google2fa")
            span {{ localErrors.google2fa }}
        .dialog-add-ip-address__input(v-if="isOtpActive")
          input(
            placeholder="Код OTP",
            v-model.trim="otpCode"
            maxlength="6"
            @input='localErrors.google2fa = ""'
          )
          .dialog-add-ip-address__input-error__text(v-if='localErrors.otp')
            span {{ localErrors.otp }}
          RefreshOtpCode
    .dialog-add-ip-address__footer(slot='footer')
      button.awesome-btn.awesome-btn-outline(@click='handleCloseDialog')
        span Отменить
      button.awesome-btn(v-loading='isLoading' @click='handleAddIPAddress' :disabled='!isComplete')
        span Сохранить
</template>

<script>
import { mapGetters } from 'vuex';
import utilsMixin from '@/components/mixins/utils';
import { PROFILE_USER_IP_ADD } from '@/store/actions/user';
import RefreshOtpCode from "../Security/RefreshOtpCode.vue";
import {SECURITY_REQUEST} from "../../../../../store/actions/security";

export default {
  name: 'DialogAddIPAddress',
  mixins: [utilsMixin],
  props: {
    opened: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dialogVisible: false,
      localErrors: {
        ip: '',
        google2fa: '',
        otp: ''
      },
      google2fa_secret: '',
      ip: '',
      otpCode: '',
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      is2FaActive: 'is2FaActive',
      isOtpActive: 'isOtpActive',
    }),
    isComplete() {
      if(this.isOtpActive) return !!this.ip && this.otpCode.length === 6
      else if(this.is2FaActive) return !!this.ip && this.google2fa_secret.length === 6
      return !!this.ip
    },
  },
  mounted() {
    this.dialogVisible = this.opened;
  },
  methods: {
    async handleAddIPAddress() {
      this.isLoading = true;
      try {
        const data = {
          ip: this.ip,
          google2fa_secret: this.google2fa_secret || null,
          otp: this.otpCode || null,
        };
        const response = await this.$store.dispatch(PROFILE_USER_IP_ADD, data)
        if(response) {
          this.$store.dispatch(SECURITY_REQUEST)
          this.$emit('update');
          this.handleCloseDialog();
          this.$message({
            message: 'IP-адрес успешно добавлен!',
            type: 'success',
          });
        }
      } catch (e) { this.setLocalErrors(e) }
      this.isLoading = false;
    },
    handleCloseDialog() {
      this.$emit('close');
    },
    setLocalErrors(error) {
      console.log(error.errors)
      this.localErrors.google2fa = error?.errors?.google2fa_secret?.join(' ') || '';
      this.localErrors.otp = error?.errors?.otp?.join(' ') || '';
      this.localErrors.ip = error?.errors?.ip?.join(' ') || '';
      if (error?.errors?.error) {
        this.$message({
          message: `Не получилось добавить IP-адрес. ${error.errors.error?.join(' ') || ''}`,
          type: 'error',
        });
      }
    },
    validateIPAddress(address) {
      const isValid = this.validate_IPv46Address(address);
      if (!isValid) this.localErrors.ip = 'Введен неверный IP-адрес';
      else this.localErrors.ip = '';
    },
  },
  components: {RefreshOtpCode},
};
</script>

<style scoped lang='scss'>
.dialog-add-ip-address {
  &__content {
    margin-top: 27px;
  }

  &__input {
    &-container {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    input {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: $gray600;

      &:focus {
        color: $gray800;
      }
    }

    &-error__text {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #FF2D55;
      padding: 2px 12px 0 16px;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 22px;
    gap: 16px;
  }
}
</style>
