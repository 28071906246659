<template lang="pug">
.link-btn(
  v-loading="isLoading"
  :class="{disabled: isDisabled}"
  @click="refreshOtpCode"
) Запросить новый код
  span(v-if="!!seconds") &nbsp; через {{seconds}} секунд
</template>

<script>
import {REFRESH_OTP_CODE} from "../../../../../store/actions/security";

export default {
  data() {
    return {
      timer: null,
      seconds: 0,
      isLoading: false,
    }
  },

  computed: {
    isDisabled() {return this.seconds > 0}
  },

  methods: {
    async refreshOtpCode() {
      if(!this.isDisabled) {
        this.isLoading = true;
        try { await this.$store.dispatch(REFRESH_OTP_CODE) }
        catch (e) {
          this.seconds = 60
          this.startTimer()
          if(e && e.message) this.$message.error(e.message);
        }
        this.isLoading = false;
      }
    },

    endTimer() {
      clearInterval(this.timer)
      this.timer = null
    },
    startTimer() {
      this.timer = setInterval(() => {
        if(this.seconds > 0) this.seconds--
        else this.endTimer()
      }, 1000)
    },

  },
}
</script>

<style lang="scss" scoped>
.link-btn {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: $blue600;
  padding: 16px 0 0 0;
  cursor: pointer;

  &.disabled {
    color: $gray200;
    cursor: not-allowed;
  }
}
</style>