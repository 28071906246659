<template lang='pug'>
  el-dialog.dialog-delete-ip-address(
    title='Удаление IP-адреса'
    width='370px'
    class='awesome-el-dialog'
    :visible.sync='dialogVisible'
    :show-close='false'
    @close='handleCloseDialog'
  )
    .dialog-delete-ip-address__content
      .dialog-delete-ip-address__desc
        span IP-адрес будет удален из списка без возможности восстановления
      .dialog-delete-ip-address__input(v-if='is2FaActive')
        input(
          placeholder='Код Google Authenticator',
          v-model.trim='google2fa_secret'
          maxlength='6'
        )
        .dialog-delete-ip-address__input-error__text(v-if='localErrors.google2fa')
          span {{ localErrors.google2fa }}
      .dialog-delete-ip-address__input(v-if="isOtpActive")
        input(
          placeholder="Код OTP",
          v-model.trim="otpCode"
          maxlength="6"
        )
        .dialog-add-ip-address__input-error__text(v-if='localErrors.otp')
          span {{ localErrors.otp }}
        RefreshOtpCode
    .dialog-delete-ip-address__footer(slot='footer')
      button.awesome-btn.awesome-btn-outline(@click='handleCancel')
        span Отменить
      button.awesome-btn(v-loading='isLoading' @click='handleDeleteIPAddress' :disabled='!isComplete')
        span Удалить
</template>

<script>
import { mapGetters } from 'vuex';
import RefreshOtpCode from "../Security/RefreshOtpCode.vue";
import { PROFILE_USER_IP_DELETE } from '@/store/actions/user';
import {SECURITY_REQUEST} from "../../../../../store/actions/security";

export default {
  name: 'DialogDeleteIPAddress',
  props: {
    opened: {
      type: Boolean,
      required: true,
    },
    ipID: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dialogVisible: false,
      google2fa_secret: '',
      otpCode: '',
      isLoading: false,
      localErrors: {
        google2fa: '',
        otp: ''
      },
    };
  },
  computed: {
    ...mapGetters({
      is2FaActive: 'is2FaActive',
      isOtpActive: 'isOtpActive',
    }),
    isComplete() {
      if(this.isOtpActive) return this.otpCode.length === 6
      else if(this.is2FaActive) return this.google2fa_secret.length === 6
      return true
    },
  },
  mounted() {
    this.dialogVisible = this.opened;
  },
  methods: {
    handleCloseDialog() {
      this.$emit('close');
    },
    handleCancel() {
      this.handleCloseDialog();
    },
    async handleDeleteIPAddress() {
      this.isLoading = true;
      try {
        const data = {google2fa_secret: this.google2fa_secret || null};
        const response = await this.$store.dispatch(PROFILE_USER_IP_DELETE, { id: this.ipID, data })
        if(response) {
          this.$store.dispatch(SECURITY_REQUEST)
          this.$emit('update');
          this.$emit('close');
          this.$message({
            message: 'IP-адрес успешно удалён!',
            type: 'success',
          });
        }
      } catch (e) { this.setLocalErrors(e) }
      this.isLoading = false;
    },
    setLocalErrors(error) {
      console.log(error.errors)
      this.localErrors.google2fa = error?.errors?.google2fa_secret?.join(' ') || '';
      this.localErrors.otp = error?.errors?.otp?.join(' ') || '';
      if (error?.errors?.error) {
        this.$message({
          message: `Не получилось удалить IP-адрес. ${error.errors.error.join(' ') || ''}`,
          type: 'error',
        });
      }
    },
  },

  components: {RefreshOtpCode}
};
</script>

<style scoped lang='scss'>
.dialog-delete-ip-address {
  &__content {
    margin-top: 8px;
  }

  &__desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $gray600;
  }

  &__input {
    margin-top: 32px;

    input {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: $gray600;

      &:focus {
        color: $gray800;
      }
    }

    &-error__text {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #FF2D55;
      padding: 2px 12px 0 16px;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 22px;
    gap: 16px;
  }
}
</style>
