<template lang='pug'>
  .awesome-profile-security__container
    .awesome-profile-security__tabs
      AwesomeTabs(
        :tabs='visibleTabs'
        :selected-tab='selectedTab'
        custom-class-nav='awesome-profile-security__tabs-nav'
        custom-class-nav-item='awesome-profile-security__tabs-nav-item'
        @changeTab='changeTab'
      )
        template(#icon)
          img.awesome-profile-security__tabs-nav-item-img(src='/awesome-icons/right-arrow.svg')
    .awesome-profile-security__tabs-content(:class='tabsContentClasses')
      component(:is='selectedTabComponent')
</template>

<script>
import { mapGetters } from 'vuex';
import AwesomeTabs from '@/components/AwesomeComponents/Tabs.vue';
import ProfileSecurityEmail
  from '@/components/AwesomeComponents/Account/Profile/Security/components/ProfileSecurityEmail.vue';
import ProfileSecurityPassword
  from '@/components/AwesomeComponents/Account/Profile/Security/components/ProfileSecurityPassword.vue';
import ProfileSecurityTwoFactor
  from '@/components/AwesomeComponents/Account/Profile/Security/components/ProfileSecurityTwoFactor.vue';
import ProfileSecurityIPAddressManagement
  from '@/components/AwesomeComponents/Account/Profile/Security/components/ProfileSecurityIPAddressManagement.vue';
import ProfileBlockSale
  from '@/components/AwesomeComponents/Account/Profile/Security/components/ProfileBlockSale.vue';

export default {
  name: 'ProfileSecurity',
  components: { AwesomeTabs },
  data() {
    return {
      tabs: [
        { name: 'email', label: 'E-mail', component: ProfileSecurityEmail },
        { name: 'password', label: 'Пароль', component: ProfileSecurityPassword },
        { name: '2fa', label: 'Двухфакторная аутентификация', component: ProfileSecurityTwoFactor },
        {
          name: 'ip_address_management',
          label: 'Управление IP-адресами',
          component: ProfileSecurityIPAddressManagement,
        },
        {
          name: 'block_sale',
          label: 'Блокировка продажи',
          component: ProfileBlockSale,
          hidden: true,
        },
      ],
      selectedTab: 'email',
    };
  },
  computed: {
    ...mapGetters({
      profile: 'getProfile',
    }),
    visibleTabs() {
      return this.tabs.filter(({ hidden }) => !hidden);
    },
    selectedTabComponent() {
      const selectedTab = this.tabs.find(tab => tab.name === this.selectedTab);
      return selectedTab ? selectedTab.component : null;
    },
    tabsContentClasses() {
      return {
        'tabs-content-max': this.selectedTab === '2fa'
          || this.selectedTab === 'ip_address_management'
          || this.selectedTab === 'block_sale',
      };
    },
  },
  watch: {
    profile() {
      this.checkBlockSaleVisibility();
    },
  },
  created() {
    this.checkBlockSaleVisibility();
  },
  methods: {
    changeTab(tabName) {
      this.selectedTab = tabName;
    },
    checkBlockSaleVisibility() {
      if (this.profile.pro_partner) {
        this.tabs.find(({ name }) => name === 'block_sale').hidden = false;
      }
    },
  },
};
</script>

<style scoped>

</style>
