<template lang='pug'>
.f-modal(@click="handleCloseDialog" v-if="dialogVisible")
  section.center-section
    .private-key(@click.stop)
      .private-key__header
        .header-title
          span Приватный ключ сгенерирован
        .header-description
          .text-subtitle Пожалуйста, скопируйте и сохраните его
            |&nbsp;в надежном месте. Для безопасности вы не сможете просмотреть этот ключ повторно.
            .text-bold В случае утери, вам нужно будет получить  новый приватный ключ
      .private-key__input
        .dialog-two-factor-private-key__input-container
          input.dialog-two-factor-private-key__input.text-ellipsis(
            ref='inputPrivateKey'
            v-model='privateKey'
            readonly
          )
          .dialog-two-factor-private-key__input-icon(@click='handleCopyKey')
            img(src='/awesome-icons/copy.svg')
      .private-key__footer
        button.awesome-btn.awesome-btn-outline(@click='handleCloseDialog') Закрыть окно
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DialogTwoFactorPrivateKey',
  props: {
    opened: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      'privateKey': 'getPrivateKey',
    }),
  },
  mounted() {
    this.dialogVisible = this.opened;
  },
  methods: {
    handleCloseDialog() {
      this.$emit('close');
    },
    handleCopyKey() {
      const google2fa_secret_key = this.$refs.inputPrivateKey.value;
      navigator.clipboard.writeText(google2fa_secret_key).then(() => {
        this.$message({
          message: 'Приватный ключ успешно скопирован',
          type: 'success',
        });
      }).catch(err => {
        console.log('Ошибка при копировании приватного ключа', err);
      });
    },
  },
};
</script>

<style scoped lang='scss'>
.dialog-two-factor-private-key {
  &__content {
    margin-top: 8px;
  }

  &__desc {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: $gray600;

    strong {
      font-weight: 500;
    }
  }

  &__input {
    &-container {
      position: relative;
      input {
        padding-right: 40px;
        color: $gray800;
      }
    }

    &-icon {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 36px;
  }
}

.private-key {
  display: flex;
  flex-direction: column;
  width: 368px;
  border-radius: 16px;
  background-color: $white;

  &__header {
    display: flex;
    gap: 4px;
    flex-direction: column;
    padding: 21px 24px 8px 24px;

    .header-title {
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
    }

    .header-description {
      display: flex;
      flex-direction: column;
      span {
        font-weight: 400;
      }
    }
  }

  &__input {
    padding: 17px 24px 20px 24px;
  }

  &__footer {
    padding: 16px 24px 24px 16px;
    display: flex;
    justify-content: flex-end;
  }
}
</style>

//el-dialog.dialog-two-factor-private-key(
//  title='Приватный ключ сгенерирован'
//  width='370px'
//  class='awesome-el-dialog'
//  :visible.sync='dialogVisible'
//  @close='handleCloseDialog'
//)
//  .dialog-two-factor-private-key__content
//    .dialog-two-factor-private-key__desc
//      span Пожалуйста, <strong>скопируйте и сохраните его</strong> в надежном месте. Для безопасности вы не сможете просмотреть этот ключ повторно. <br> В случае утери, вам нужно будет получить  новый приватный ключ
//    .dialog-two-factor-private-key__input-container
//      input.dialog-two-factor-private-key__input(
//        ref='inputPrivateKey'
//        v-model='privateKey'
//        readonly
//      )
//      .dialog-two-factor-private-key__input-icon(@click='handleCopyKey')
//        img(src='/awesome-icons/copy.svg')
//  .dialog-two-factor-private-key__footer(slot='footer')
//    button.awesome-btn.awesome-btn-outline(@click='handleCloseDialog')
//      span Закрыть окно
