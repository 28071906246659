<template lang='pug'>
  .awesome-profile-security__content
    .awesome-profile-security__content-title
      span Изменение
    .awesome-profile-security__content-desc
      span Текущий e-mail адрес:
        strong  {{ profile.email }}

    .awesome-profile-security__content-container
      .awesome-profile-security__inputs-block
        .awesome-profile-security__input
          input(
            placeholder='Новый e-mail',
            v-model.trim='form.email'
            @blur='handleEmailValidate($event.target.value)'
          )
          .awesome-profile-security__input-error__text(v-if='isErrorEmailText')
            span {{ isErrorEmailText }}
        .awesome-profile-security__input-password-container
          input.awesome-profile-security__input-password(
            placeholder='Текущий пароль',
            v-model.trim='form.password'
            :type='inputType',
          )
          .awesome-profile-security__input-password-icon(@click='togglePasswordVisibility')
            span
              i.el-icon-view
          .awesome-profile-security__input-error__text(v-if='(getErrors && getErrors.message) && getErrors.errors.password')
            template(v-for='error in getErrors.errors.password')
              span {{ error }}
        .awesome-profile-security__input(v-if='isGoggle2faActivated')
          input(
            placeholder='Код Google Authenticator',
            v-model.trim='form.google2fa_secret'
            maxlength='6'
          )
          .awesome-profile-security__input-error__text(v-if='(getErrors && getErrors.message) && getErrors.errors.google2fa_secret')
            template(v-for='error in getErrors.errors.google2fa_secret')
              span {{ error }}
      .awesome-profile-security__btn-block
        button.awesome-btn.awesome-btn-outline(@click='handleClearsInputs' :disabled='!isDisabledClearableBtn')
          span Очистить
        button.awesome-btn(@click='handleChangeEmail' :disabled='isDisabledSaveBtn')
          span Сохранить
      .awesome-profile-security__email-confirm-block(v-if='!security.email_confirmed')
        .awesome-profile-security__content-title
          span Подтверждение
        .awesome-profile-security__content-desc
          span Мы отправим вам на почту письмо с ссылкой для
          br
          | подтверждения e-mail
        .awesome-profile-security__email-confirm-block__footer
          .awesome-profile-security__email-confirm-block__btn
            template(v-if='isDisabledConfirmEmail')
              el-tooltip(
                effect='dark'
                placement='top'
                content='Вы можете повторить запрос ссылки через 2 минуты'
              )
                button.awesome-btn.awesome-btn-outline(:class='{ disabled: isDisabledConfirmEmail }')
                  span Отправить ссылку
            template(v-else)
              button.awesome-btn.awesome-btn-outline(@click='handleConfirmEmail' :disabled='isDisabledConfirmEmail')
                span Отправить ссылку
          .awesome-profile-security__email-confirm-block__text(v-if="closeStartConfirmEmail")
            img(src='/awesome-icons/btn-success-check.svg', alt='')
            span Ссылка для подтверждения успешно отправлена
</template>

<script>
import utils from '@/components/mixins/utils';
import { EMAIL_START_CONFIRM, EMAIL_UPDATE } from '@/store/actions/user';
import { mapGetters } from 'vuex';

export default {
  name: 'ProfileSecurityEmail',
  mixins: [utils],
  data() {
    return {
      form: {
        email: '',
        password: '',
        google2fa_secret: '',
      },
      minChars: 6,
      showPassword: false,
      isErrorEmailText: '',
      closeStartConfirmEmail: false,
      timeout: null,
      isDisabledConfirmEmail: false,
      timeoutDisabledConfirmBtn: null,
    };
  },
  computed: {
    ...mapGetters({
      'security': 'getSecurity',
      'profile': 'getProfile',
      'getErrors': 'getEmailChangeErrors',
    }),
    inputType() {
      return this.showPassword ? 'text' : 'password';
    },
    isDisabledClearableBtn() {
      return Object.values(this.form).some(value => value !== '');
    },
    isDisabledSaveBtn() {
      return this.form.email.length <= this.minChars || this.form.password.length <= this.minChars;
    },
    isGoggle2faActivated() {
      return this.security.google2fa_status === 'activated';
    },
  },
  beforeDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    if (this.timeoutDisabledConfirmBtn) {
      clearTimeout(this.timeoutDisabledConfirmBtn);
    }
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    handleClearsInputs() {
      this.form = {
        email: '',
        password: '',
        google2fa_secret: '',
      };
      this.isErrorEmailText = '';
    },
    handleEmailValidate(email) {
      const regex = this.validateEmail(email);
      if (!regex) {
        this.isErrorEmailText = 'Некорректный E-mail';
      } else {
        this.isErrorEmailText = '';
      }
    },
    handleChangeEmail() {
      if (!this.isGoggle2faActivated) {
        delete this.form.google2fa_secret;
      }
      this.$store.dispatch(EMAIL_UPDATE, this.form)
        .then(() => {
          this.handleClearsInputs();
          this.$message({
            message: 'E-mail успешно изменен!',
            type: 'success',
          });
        });
    },
    handleConfirmEmail() {
      this.$store.dispatch(EMAIL_START_CONFIRM)
        .then(() => {
          this.closeStartConfirmEmail = true;
          this.timeout = setTimeout(() => {
            this.closeStartConfirmEmail = false;
          }, 5000);

          this.isDisabledConfirmEmail = true;
          this.timeoutDisabledConfirmBtn = setTimeout(() => {
            this.isDisabledConfirmEmail = false;
          }, 120000);
        });
    },
  },
};
</script>

<style scoped>

</style>
