<template lang='pug'>
  .awesome-profile-security__content
    .awesome-profile-security__content-title
      span Блокировка продажи
    .awesome-profile-security__content-desc
      p Блокирует возможность открывать сделки <nobr>с целью</nobr> продажи криптовалюты.
      p Используйте вместе с включённой двухфакторной аутентификацией, если есть риск, что управление аккаунтом может быть перехвачено.
    .awesome-profile-security__content-switch(
      v-loading="isLoading"
      @click="toggleBlockCryptoSell"
    )
      | Заблокировать продажу
      label.awesome-switch
        input.awesome-checkbox(
          type='checkbox'
          :checked='Boolean(security.crypto_selling_disabled)'
          :disabled='isLoading'
          @click.prevent="/* just prevent default behaivor */"
        )
        span.awesome-slider
    el-dialog.standard-dialog.awesome-el-dialog.awesome-profile-security-2fa-confirm-modal(
      :title="security.crypto_selling_disabled ? 'Подтвердите разблокировку продажи' : 'Подтвердите блокировку продажи'"
      width="450px"
      :visible.sync="is2FAConfirmModalOpened"
    )
      .awesome-profile-security-2fa-confirm-modal__content
        form(@submit.prevent="toggleBlockCryptoSell")
          input.awesome-profile-security-2fa-confirm-modal__2fa-input(
            placeholder="Код Google Authenticator"
            tabindex="1"
            autofocus
            ref="input2FA"
            v-model="google2FASecret"
            @input="onGoogle2FAInput"
          )
          .awesome-profile-security-2fa-confirm-modal__2fa-input-error
            | {{ google2FASecretError }}
      .awesome-profile-security-2fa-confirm-modal__actions
        button.awesome-btn.awesome-btn-outline(
          tabindex="3"
          @click="is2FAConfirmModalOpened = false"
        ) Отменить
        button.awesome-btn(
          :disabled="!google2FASecret"
          tabindex="2"
          @click="toggleBlockCryptoSell"
        ) {{ security.crypto_selling_disabled ? 'Разблокировать продажу' : 'Заблокировать продажу' }}
</template>

<script>
import { mapGetters } from 'vuex';
import {
  SECURITY_REQUEST,
  CRYPTO_SELL_ON,
  CRYPTO_SELL_OFF,
} from '@/store/actions/security';

export default {
  name: 'ProfileBlockSale',
  data() {
    return {
      google2FASecret: '',
      google2FASecretError: '',
      is2FAConfirmModalOpened: false,
    };
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      security: 'getSecurity',
      is2FaActive: 'is2FaActive',
    }),
    isLoading() {
      return this.loading(SECURITY_REQUEST) === 'loading'
        || this.loading(CRYPTO_SELL_ON) === 'loading'
        || this.loading(CRYPTO_SELL_OFF) === 'loading';
    },
  },
  watch: {
    async is2FAConfirmModalOpened(isOpenedNow) {
      if (isOpenedNow) {
        await this.$nextTick(); 
        this.focusGoogle2FAInput();
      } else {
        this.google2FASecret = '';
        this.google2FASecretError = '';
      }
    },
  },
  created() {
    this.loadSecurity();
  },
  methods: {
    loadSecurity() {
      this.$store.dispatch(SECURITY_REQUEST);
    },
    async toggleBlockCryptoSell() {
      if (this.security.google2fa_status === 'activated' && !this.google2FASecret) {
        this.is2FAConfirmModalOpened = true;
        return;
      }

      const method = this.security.crypto_selling_disabled ? CRYPTO_SELL_ON : CRYPTO_SELL_OFF;

      try {
        await this.$store.dispatch(method, { google2fa_secret: this.google2FASecret });
        this.is2FAConfirmModalOpened = false;
      } catch (error) {
        if (error.errors && error.errors.google2fa_secret) {
          this.google2FASecretError = error.errors.google2fa_secret.join(' ');
          this.focusGoogle2FAInput();
        } else {
          this.$message.error(error.message);
        }
      } finally {
        this.google2FASecret = '';
      }
    },
    onGoogle2FAInput() {
      this.google2FASecretError = '';
    },
    focusGoogle2FAInput() {
      this.$refs.input2FA.focus();
    },
  },
};
</script>

<style scoped>

</style>
