<template lang='pug'>
  .awesome-profile-security__content-two-factor__disable-container
    .awesome-profile-security__content-two-factor__disable-block
      .awesome-profile-security__content-two-factor__disable-subheader
        span Пауза
      .awesome-profile-security__content-two-factor__disable-desc
        span Останавливает на время 2FA, можно в любой момент активировать снова
      .awesome-profile-security__input-two-factor-code-container
        .awesome-profile-security__input-two-factor-code
          input(
            placeholder='Код Google Authenticator',
            v-model='google2fa_secret_pause'
            maxlength='6'
          )
          .awesome-profile-security__input-two-factor-code__error-text(v-if='errorText.google2fa_secret_pause')
            span {{ errorText.google2fa_secret_pause }}
      .awesome-profile-security__content-two-factor__disable-btn
        button.awesome-btn.awesome-btn-outline(
          @click='handleTwoFactorPauseOrActive'
          :disabled='isDisabledPauseBtn'
        )
          span {{ security.google2fa_status === 'activated' ? 'Приостановить' : 'Возобновить' }}
    .awesome-profile-security__content-two-factor__disable-block
      .awesome-profile-security__content-two-factor__disable-subheader
        span Отключение
      .awesome-profile-security__content-two-factor__disable-desc
        span Полностью отключает 2FA, для повторного включения нужно будет заново подключать Google Authenticator
      .awesome-profile-security__input-two-factor-code-container
        .awesome-profile-security__input-two-factor-code
          input(
            placeholder='Код Google Authenticator',
            v-model='google2fa_secret_stop'
            maxlength='6'
          )
          .awesome-profile-security__input-two-factor-code__error-text(v-if='errorText.google2fa_secret_stop')
            span {{ errorText.google2fa_secret_stop }}
      .awesome-profile-security__content-two-factor__disable-btn
        button.awesome-btn.awesome-btn-outline(@click='handleTwoFactorStop' :disabled='isDisabledStopBtn')
          span Отключить

    .awesome-profile-security__content-two-factor__disable-block
      .awesome-profile-security__content-two-factor__disable-subheader
        span Приватный ключ
      .awesome-profile-security__content-two-factor__disable-desc
        span Вы можете использовать его для двухфакторной авторизации при работе бота с API
      br
      .awesome-profile-security__content-two-factor__disable-desc
        span Приватный ключ используется для верификации:
        p 1. Отпуск биткоина в сделке
        p 2. Отпуск претензии
        p 3. Вывод биткоина
        p 4. Авторизация
      .awesome-profile-security__input-two-factor-code-container(v-if='!security.is_private_key_issued')
        .awesome-profile-security__input-two-factor-code
          input(
            placeholder='Код Google Authenticator'
            v-model='google2fa_secret_private'
            maxlength='6'
          )
          .awesome-profile-security__input-two-factor-code__error-text(v-if='errorText.google2fa_secret_private')
            span {{ errorText.google2fa_secret_private }}
      .private-key__issued(v-else)
        .up-description
          .blue-warning
          | Вы уже получили приватный ключ. Его нельзя посмотреть повторно.
        .down-description Чтобы получить новый ключ, необходимо отключить и заново включить двухфакторную аутентификацию.
      .awesome-profile-security__content-two-factor__disable-btn
        button.awesome-btn.awesome-btn-outline(
          @click='handlePrivateKeyConnect'
          :disabled='isDisabledPrivateBtn'
          v-if='!security.is_private_key_issued'
        )
          span Получить
    template(v-if='visibleDialog')
      DialogTwoFactorPrivateKey(:opened='visibleDialog' @close='handleCloseDialog')
</template>

<script>
import { FACTOR_DISABLE, FACTOR_DISCONNECT, FACTOR_ENABLE, PRIVATE_KEY_CONNECT } from '@/store/actions/twoFactor';
import { mapGetters } from 'vuex';
import DialogTwoFactorPrivateKey
  from '@/components/AwesomeComponents/Account/Profile/Dialogs/DialogTwoFactorPrivateKey.vue';

export default {
  name: 'ProfileSecurityTwoFactorPauseOrStop',
  components: { DialogTwoFactorPrivateKey },
  data() {
    return {
      google2fa_secret_pause: '',
      google2fa_secret_stop: '',
      google2fa_secret_private: '',
      errorText: {
        google2fa_secret_pause: '',
        google2fa_secret_stop: '',
        google2fa_secret_private: '',
      },
      visibleDialog: false,
    };
  },
  computed: {
    ...mapGetters({
      'security': 'getSecurity',
    }),
    isDisabledPauseBtn() {
      return this.google2fa_secret_pause.length !== 6;
    },
    isDisabledStopBtn() {
      return this.google2fa_secret_stop.length !== 6;
    },
    isDisabledPrivateBtn() {
      return this.google2fa_secret_private.length !== 6;
    },
  },
  methods: {
    handleTwoFactorPauseOrActive() {
      if (this.security.google2fa_status === 'activated') {
        this.handleTwoFactorPause();
      } else {
        this.handleTwoFactorEnable();
      }
    },
    handleTwoFactorStop() {
      this.$store.dispatch(FACTOR_DISCONNECT, {
        google2fa_secret: this.google2fa_secret_stop,
      })
        .then(() => {
          this.handleClears();
          this.$emit('update');
        })
        .catch((error) => {
          if (error && error.errors) {
            this.errorText.google2fa_secret_stop = error.errors.google2fa_secret.join();
          }
        });
    },
    handleTwoFactorPause() {
      this.$store.dispatch(FACTOR_DISABLE, {
        google2fa_secret: this.google2fa_secret_pause,
      })
        .then(() => {
          this.handleClears();
          this.$emit('update');
        })
        .catch((error) => {
          if (error && error.errors) {
            this.errorText.google2fa_secret_pause = error.errors.google2fa_secret.join();
          }
        });
    },
    handleTwoFactorEnable() {
      this.$store.dispatch(FACTOR_ENABLE, {
        google2fa_secret: this.google2fa_secret_pause,
      })
        .then(() => {
          this.handleClears();
          this.$emit('update');
        })
        .catch((error) => {
          if (error && error.errors) {
            this.errorText.google2fa_secret_pause = error.errors.google2fa_secret.join();
          }
        });
    },
    handlePrivateKeyConnect() {
      this.$store.dispatch(PRIVATE_KEY_CONNECT, {
        google2fa_secret: this.google2fa_secret_private,
      })
        .then(() => {
          this.handleOpenDialog();
          this.handleClears();
          this.$emit('update');
        })
        .catch((error) => {
          if (error && error.errors) {
            this.errorText.google2fa_secret_private = error.errors.google2fa_secret.join();
          }
        });
    },
    handleOpenDialog() {
      this.visibleDialog = true;
    },
    handleCloseDialog() {
      this.visibleDialog = false;
    },
    handleClears() {
      this.google2fa_secret_pause = '';
      this.google2fa_secret_stop = '';
      this.google2fa_secret_private = '';
      this.errorText = {
        google2fa_secret_pause: '',
        google2fa_secret_stop: '',
        google2fa_secret_private: '',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.private-key__issued {
  padding-top: 18px;
  .up-description, .down-description {
    color: $gray800;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }

  .up-description {
    display: flex;
    gap: 8px;
    flex-direction: row;
    .blue-warning {
      height: 24px;
      width: 24px;
      padding-right: 20px;
      background-image: url('/awesome-icons/blue-warning.svg');
    }
  }
  .down-description {
    padding-top: 10px;
  }
}
</style>
