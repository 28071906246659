import { render, staticRenderFns } from "./ProfileSecurityIPAddressManagement.vue?vue&type=template&id=ce0aedfc&scoped=true&lang=pug&"
import script from "./ProfileSecurityIPAddressManagement.vue?vue&type=script&lang=js&"
export * from "./ProfileSecurityIPAddressManagement.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce0aedfc",
  null
  
)

export default component.exports