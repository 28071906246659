import { render, staticRenderFns } from "./ProfileSecurityTwoFactor.vue?vue&type=template&id=dc05342e&scoped=true&lang=pug&"
import script from "./ProfileSecurityTwoFactor.vue?vue&type=script&lang=js&"
export * from "./ProfileSecurityTwoFactor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc05342e",
  null
  
)

export default component.exports