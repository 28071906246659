<template lang='pug'>
  .awesome-profile-security__content.awesome-profile-security__content-ip-address(v-loading='isLoading')
    .awesome-profile-security__content-ip-address__title-container
      .awesome-profile-security__content-ip-address__title
        .awesome-profile-security__content-title
          span IP-адреса
        .awesome-profile-security__content-desc
          span Список доверенных IP-адресов, которым вы разрешаете доступ на платформу
      .awesome-profile-security__content-ip-address__title-btn
        button.awesome-btn.awesome-btn-outline(@click='handleOpenDialogAddIPAddress')
          span Добавить
    .awesome-profile-security__content-ip-address__content-empty(v-if='!getUserIPList.length')
      .awesome-profile-security__content-ip-address__content-empty__icon
      .awesome-profile-security__content-ip-address__content-empty__text
        span У вас пока что нет добавленных IP-адресов
    .awesome-profile-security__content-ip-address__content(v-else)
      table.awesome-profile-security__content-ip-address__table
        thead
          tr
            th(v-for='header in headers' :key='header.value') {{ header.label }}
        tbody
          tr.awesome-profile-security__content-ip-address__table-cell(v-for='item in getUserIPList' :key='item.id')
            td(v-for='header in headers' :key='header.value')
              span(v-if='header.value === "created_at"') {{ divideDate(item.created_at).date }} {{ divideDate(item.created_at).time }}
              span.awesome-profile-security__content-ip-address__table-cell-icon(
                v-else-if='header.value === "action"'
                @click='handleOpenDialogDeleteIPAddress(item.id)'
              )
                img(src='/awesome-icons/delete.svg')
              span(v-else) {{ item[header.value] }}
      el-pagination(
        v-if='pagination.total_pages > 1',
        :total='pagination.total',
        :page-size='parseInt(pagination.per_page)',
        :current-page='pagination.current_page',
        layout='prev, pager, next',
        @current-change='getIPAddressList',
      )

    template(v-if='visibleDialogAddIPAddress')
      DialogAddIPAddress(
        :opened='visibleDialogAddIPAddress'
        @close='handleCloseDialogAddIPAddress'
        @update='getIPAddressList'
      )

    template(v-if='visibleDialogDeleteIPAddress')
      DialogDeleteIPAddress(
        :opened='visibleDialogDeleteIPAddress'
        :ipID='ipID'
        @close='handleCloseDialogDeleteIPAddress'
        @update='getIPAddressList'
      )
</template>

<script>
import { mapGetters } from 'vuex';
import { PROFILE_USER_IP_ADD, PROFILE_USER_IP_DELETE, PROFILE_USER_IP_LIST } from '@/store/actions/user';
import utilsMixin from '@/components/mixins/utils';
import DialogDeleteIPAddress from '@/components/AwesomeComponents/Account/Profile/Dialogs/DialogDeleteIPAddress.vue';
import DialogAddIPAddress from '@/components/AwesomeComponents/Account/Profile/Dialogs/DialogAddIPAddress.vue';

export default {
  name: 'ProfileSecurityIPAddressManagement',
  components: { DialogAddIPAddress, DialogDeleteIPAddress },
  mixins: [utilsMixin],
  data() {
    return {
      headers: [
        { label: 'IP-адрес', value: 'ip' },
        { label: 'Добавлено', value: 'created_at' },
        { label: '', value: 'action' },
      ],
      ipID: null,
      visibleDialogDeleteIPAddress: false,
      visibleDialogAddIPAddress: false,
      pagination: {
        page: 1,
        per_page: 10,
      },
    };
  },
  computed: {
    ...mapGetters(['getUserIPList', 'loading']),
    isLoading() {
      return this.loading(PROFILE_USER_IP_LIST) === 'loading' ||
        this.loading(PROFILE_USER_IP_DELETE) === 'loading' ||
        this.loading(PROFILE_USER_IP_ADD) === 'loading';
    },
  },
  async mounted() {
    await this.getIPAddressList();
  },
  methods: {
    async getIPAddressList(page = 1) {
      const result = await this.$store.dispatch(PROFILE_USER_IP_LIST, { params: { page, per_page: this.pagination.per_page } });
      this.pagination = {
        ...result.meta,
        total_pages: result.meta.last_page,
      };
    },
    handleOpenDialogAddIPAddress() {
      this.visibleDialogAddIPAddress = true;
    },
    handleCloseDialogAddIPAddress() {
      this.visibleDialogAddIPAddress = false;
    },
    handleOpenDialogDeleteIPAddress(id) {
      this.ipID = id;
      this.visibleDialogDeleteIPAddress = true;
    },
    handleCloseDialogDeleteIPAddress() {
      this.ipID = null;
      this.visibleDialogDeleteIPAddress = false;
    },
  },
};
</script>

<style scoped>

</style>
