<template lang='pug'>
  .awesome-profile-security__content
    .awesome-profile-security__content-title
      span Изменение
    .awesome-profile-security__content-container
      .awesome-profile-security__inputs-block
        .awesome-profile-security__input-password-container
          input.awesome-profile-security__input-password(
            placeholder='Текущий пароль',
            v-model='form.password'
            :type='inputTypes.password'
          )
          .awesome-profile-security__input-password-icon(@click='togglePasswordVisibility("password")')
            span
              i.el-icon-view
          .awesome-profile-security__input-error__text(v-if='(getErrors && getErrors.message) && getErrors.errors.password')
            template(v-for='error in getErrors.errors.password')
              span {{ error }}
        .awesome-profile-security__input-new-password-block
          .awesome-profile-security__input-password-container
            input.awesome-profile-security__input-password(
              placeholder='Новый пароль',
              v-model='form.new_password'
              :type='inputTypes.new_password'
            )
            .awesome-profile-security__input-password-icon(@click='togglePasswordVisibility("new_password")')
              span
                i.el-icon-view
          .awesome-profile-security__input-new-password-validation__container
            .awesome-profile-security__input-new-password-validation
              .awesome-profile-security__input-new-password-validation-row(v-for='validation in passwordValidations' :key='validation.key')
                .awesome-profile-security__input-new-password-validation-row__icon(:class='{ checked: passwordValidation[validation.key] }')
                span(:class='{ checked: passwordValidation[validation.key] }') {{ validation.text }}
        .awesome-profile-security__input-password-container
          input.awesome-profile-security__input-password(
            placeholder='Подтвердите новый пароль',
            v-model='form.new_password_confirmation'
            :type='inputTypes.new_password_confirmation',
          )
          .awesome-profile-security__input-password-icon(@click='togglePasswordVisibility("new_password_confirmation")')
            span
              i.el-icon-view
          .awesome-profile-security__input-error__text(v-if='passwordValidation.different')
            span Пароли не совпадают
        .awesome-profile-security__input(v-if='isGoggle2faActivated')
          input(
            placeholder='Код Google Authenticator',
            v-model.trim='form.google2fa_secret'
            maxlength='6'
          )
          .awesome-profile-security__input-error__text(v-if='(getErrors && getErrors.message) && getErrors.errors.google2fa_secret')
            template(v-for='error in getErrors.errors.google2fa_secret')
              span {{ error }}
      .awesome-profile-security__btn-block
        button.awesome-btn.awesome-btn-outline(@click='handleClearsInputs' :disabled='!isDisabledClearableBtn')
          span Очистить
        button.awesome-btn(@click='handleChangePassword' :disabled='!isPasswordTried')
          span Сохранить
</template>

<script>

import { mapGetters } from 'vuex';
import { PASSWORD_UPDATE } from '@/store/actions/user';

export default {
  name: 'ProfileSecurityPassword',
  data() {
    return {
      form: {
        password: '',
        new_password: '',
        new_password_confirmation: '',
        google2fa_secret: '',
      },
      inputTypes: {
        password: 'password',
        new_password: 'password',
        new_password_confirmation: 'password',
      },
      passwordValidations: [
        { key: 'uppercase', text: '1 заглавная буква' },
        { key: 'number', text: 'минимум 1 цифра' },
        { key: 'lowercase', text: '1 прописная буква' },
        { key: 'count', text: 'более 7 символов' },
      ],
      passwordValidation: {
        none: true,
        same: false,
        different: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      'security': 'getSecurity',
      'getErrors': 'getchangePasswordErrors',
    }),
    isDisabledClearableBtn() {
      return Object.values(this.form).some(value => value !== '');
    },
    isPasswordTried() {
      const { same, count, letter, uppercase, lowercase, number } = this.passwordValidation;
      const isGoogle2faEmpty = this.form.google2fa_secret;
      const isPasswordEmpty = this.form.password;
      if (this.isGoggle2faActivated) {
        return same && count && letter && uppercase && lowercase && number && isPasswordEmpty && isGoogle2faEmpty;
      } else {
        return same && count && letter && uppercase && lowercase && number && isPasswordEmpty;
      }
    },
    isGoggle2faActivated() {
      return this.security.google2fa_status === 'activated';
    },
  },
  watch: {
    'form.new_password': 'handleCheckPassword',
    'form.new_password_confirmation': 'handleCheckPassword',
  },
  methods: {
    handleClearsInputs() {
      this.form = {
        password: '',
        new_password: '',
        new_password_confirmation: '',
        google2fa_secret: '',
      };
    },
    togglePasswordVisibility(input) {
      this.inputTypes[input] = this.inputTypes[input] === 'password' ? 'text' : 'password';
    },
    handleCheckPassword() {
      this.passwordValidation.none = (this.form.password === '' && this.form.new_password === '' && this.form.new_password_confirmation === '');
      this.passwordValidation.same = (
        this.form.new_password === this.form.new_password_confirmation &&
        this.form.new_password !== '' && this.form.new_password_confirmation !== ''
      );
      this.passwordValidation.different = (this.form.new_password !== this.form.new_password_confirmation);

      this.passwordValidation.number = new RegExp(/.*[0-9]/).test(this.form.new_password);
      this.passwordValidation.lowercase = new RegExp(/.*[a-z]/).test(this.form.new_password);
      this.passwordValidation.uppercase = new RegExp(/.*[A-Z]/).test(this.form.new_password);
      this.passwordValidation.letter = new RegExp(/.*[A-Za-z]/).test(this.form.new_password);
      this.passwordValidation.count = this.form.new_password.length >= 7;
    },
    handleChangePassword() {
      if (!this.isGoggle2faActivated) {
        delete this.form.google2fa_secret;
      }
      this.$store.dispatch(PASSWORD_UPDATE, this.form)
        .then(() => {
          this.handleClearsInputs();
          this.$message({
            message: 'Пароль успешно изменен!',
            type: 'success',
          });
        });
    },
  },
};
</script>

<style scoped>

</style>
